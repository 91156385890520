import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient, private readonly cs: CookieService) { }

  async getData(wpsid: string, id: number) {
    const url = `${environment.endPoint}/WebappAdmin/GetUsuario?opt=id&id=${id}&wpsid=${wpsid}`;
    let userData = null;
    await this.http.get(url).toPromise()
    .then(data => {
      userData = data;
    }).catch(error => console.log(error));
    return userData;
  }
  
  async getDataCore( id: number) {
    const url = `${environment.coreEndpoint}/user/${id}?deepLoadingClient=true`;
    let userData = null;
    await this.http.get(url).toPromise()
    .then(data => {
      if (!data || Object.keys(data).length === 0) {
        userData = null;
      } else {
        userData = data;
      }
    }).catch(error => {
      console.log(error) 
      return null;
    });
    return userData;
  }

   updateData(data:any){
     const wpsid = this.cs.get('wpsid');
     const url = `${environment.endPoint}/WebappAdmin/UpdateUsuarioPerfil?wpsid=${wpsid}`;
     const httpOptions = {
       headers: new HttpHeaders().set(
         'Content-Type', 'application/x-www-form-urlencoded')
     };
     let formBody:any = [];
     for (const property in data) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(data[property]);
       formBody.push(`${encodedKey}=${encodedValue}`);
     }
     formBody = formBody.join("&");
    this.http.post(url,formBody, httpOptions).toPromise().then(data => {
      console.log(data)
    }).catch(error => console.log(error));
     return data;
  }

}
