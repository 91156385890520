import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuickSightService {
  constructor(private http: HttpClient) {}
  getEmbedUrl(): Observable<string> {
    let idUserPublic = localStorage.getItem('id');
    let apiUrl = 'https://aydftmiodj.execute-api.us-east-1.amazonaws.com/v1/obtener?id='+idUserPublic; 

    return this.http.get<any>(apiUrl).pipe(
      map(response => {
        if (response.embedUrl) {
          const parsedBody = response.embedUrl;
          return parsedBody;
        }
        throw new Error("No se pudo obtener la URL del tablero");
      })
    );
  };
}
