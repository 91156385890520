import { Component } from '@angular/core';

@Component({
  selector: 'app-new-modulo-bi',
  templateUrl: './new-modulo-bi.component.html', // Usa el HTML externo
  styleUrls: ['./new-modulo-bi.component.css']  // Usa el CSS externo
})
export class NewModuloBiComponent {
   // JSON de respuesta
}
