<mat-sidenav-container class="dashboard-container">
  <mat-sidenav #sidenav class="relative" mode="side" [(opened)]="sideBarOpen">
    <div class="overlay" *ngIf="isClosingSession">
      <mat-progress-spinner mode="indeterminate" value="40" style="display: flex; justify-content: center; align-items: center;"></mat-progress-spinner>
    </div>
    <div class="logo-sidebar">
      <svg width="200" height="33" viewBox="0 0 150 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2953 31.7487C9.09624 31.7487 7.04593 30.8983 5.54849 29.3984C3.99898 27.8489 3.19819 25.8506 3.19819 23.6516V14.1562H0V9.00932H3.19819V0.00976562H9.14583V9.00684H15.7926V14.1537H9.14583V23.5499C9.14583 24.7994 10.2466 25.9498 11.3449 25.9498H15.6934L17.2925 31.7487H11.2953Z" fill="url(#paint0_linear_360_477)"/>
        <path d="M20.0396 31.7496V8.85898H26.3368V10.7085C28.1367 8.90856 30.8341 8.30859 32.634 8.30859H34.1339L32.634 14.5562H31.6844C28.7366 14.5562 26.3368 16.8545 26.3368 19.6039V31.7496H20.0396H20.0396Z" fill="url(#paint1_linear_360_477)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M34.7338 20.3015C34.7338 23.4501 35.9833 26.45 38.2319 28.7482C40.5302 30.9969 43.53 32.1968 46.6786 32.1968C48.4785 32.1968 50.5264 31.9489 52.3263 30.1986L52.5767 29.9482V31.7481H58.6235V8.85747H52.5767V10.8557L52.3263 10.6549C50.6776 9.15497 48.7785 8.40625 46.6786 8.40625C43.4804 8.40625 40.4806 9.60619 38.2319 11.8548C35.9833 14.1531 34.7338 17.1529 34.7338 20.3015ZM46.6786 26.3409C43.4804 26.3409 40.7806 23.5939 40.7806 20.2941C40.7806 17.0463 43.4804 14.2473 46.6786 14.2473C49.8768 14.2473 52.5767 16.9967 52.5767 20.2941C52.5767 23.5914 49.8768 26.3409 46.6786 26.3409Z" fill="url(#paint2_linear_360_477)"/>
        <path d="M78.7647 31.7481V19.5032C78.7647 16.8034 76.6152 14.7059 73.9153 14.7059C71.2155 14.7059 69.066 16.8554 69.066 19.5032V31.7481H62.8184V8.85747H69.066V10.5061C70.6155 8.95664 72.2642 8.40625 73.9153 8.40625C76.9152 8.40625 79.7142 9.55661 81.8116 11.6044C83.9115 13.7539 85.0594 16.553 85.0594 19.5007V31.7456H78.7622L78.7647 31.7481Z" fill="url(#paint3_linear_360_477)"/>
        <path d="M93.8259 30.5568L93.7664 30.5246C91.0418 28.9503 89.0262 26.4017 88.1609 23.3275C87.2957 20.2458 87.6775 16.9707 89.1848 14.2139C90.7591 11.4892 93.3078 9.47359 96.4316 8.59843C99.605 7.70591 102.821 8.04804 105.496 9.6397C106.113 9.98927 106.621 10.314 107.077 10.6487L107.903 11.3578L104.172 16.1972L103.239 15.4733C101.648 14.256 99.6992 13.7651 98.0158 14.2411C94.9837 15.0915 93.1416 18.5153 94.0342 21.6961C94.9267 24.8695 98.2835 26.8355 101.316 25.9852C102.999 25.5092 104.415 24.0787 105.106 22.1201L105.491 21.0788L111.389 23.2109L110.923 24.3266C110.863 24.5026 110.764 24.6836 110.655 24.867C110.496 25.2241 110.305 25.6406 110.097 26.0075C108.572 28.7222 106.024 30.7304 102.9 31.613C99.8182 32.4634 96.5927 32.0716 93.8284 30.5544L93.8259 30.5568Z" fill="url(#paint4_linear_360_477)"/>
        <path d="M135.494 31.7487C133.295 31.7487 131.245 30.8983 129.747 29.3984C128.198 27.8489 127.397 25.8506 127.397 23.6516V14.1562H124.199V9.00932H127.397V0.00976562H133.345V9.00684H139.496V14.1537H133.345V23.5499C133.345 24.7994 134.445 25.9498 135.544 25.9498H139.892L141.491 31.7487H135.494Z" fill="url(#paint5_linear_360_477)"/>
        <path d="M135.494 31.7487C133.295 31.7487 131.245 30.8983 129.747 29.3984C128.198 27.8489 127.397 25.8506 127.397 23.6516V14.1562H124.199V9.00932H127.397V0.00976562H133.345V9.00684H139.496V14.1537H133.345V23.5499C133.345 24.7994 134.445 25.9498 135.544 25.9498H139.892L141.491 31.7487H135.494Z" fill="#6226F7"/>
        <path d="M142.252 3.85022C142.252 2.80151 142.652 1.85197 143.403 1.15036C144.152 0.401633 145.101 0 146.152 0C148.252 0 150 1.75033 150 3.84774C150 5.94516 148.25 7.69549 146.152 7.69549C144.003 7.69549 142.255 5.94516 142.255 3.84774L142.252 3.85022Z" fill="#D41EB9"/>
        <path d="M142.902 9.20703H149.4V31.7406H144.39L142.902 25.9492V9.20703V9.20703Z" fill="#6226F7"/>
        <path d="M117.768 7.70135C115.668 7.70135 113.92 5.95102 113.92 3.8536C113.92 1.75371 115.67 0.00585938 117.768 0.00585938C118.816 0.00585938 119.766 0.405013 120.517 1.15622C121.266 1.85535 121.667 2.80489 121.667 3.85608C121.667 5.95598 119.917 7.70382 117.77 7.70382L117.768 7.70135Z" fill="url(#paint6_linear_360_477)"/>
        <path d="M121.015 25.9492V31.7406H114.517V9.20703H121.015V25.9492V25.9492Z" fill="url(#paint7_linear_360_477)"/>
        <defs>
        <linearGradient id="paint0_linear_360_477" x1="-6.85007" y1="158.192" x2="148.225" y2="158.192" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D41EB9"/>
        <stop offset="0.18" stop-color="#CF1EBB"/>
        <stop offset="0.37" stop-color="#C21FC2"/>
        <stop offset="0.56" stop-color="#AD20CE"/>
        <stop offset="0.77" stop-color="#8E22DE"/>
        <stop offset="0.97" stop-color="#6825F3"/>
        <stop offset="1" stop-color="#6226F7"/>
        </linearGradient>
        <linearGradient id="paint1_linear_360_477" x1="-6.85002" y1="148.984" x2="148.225" y2="148.984" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D41EB9"/>
        <stop offset="0.18" stop-color="#CF1EBB"/>
        <stop offset="0.37" stop-color="#C21FC2"/>
        <stop offset="0.56" stop-color="#AD20CE"/>
        <stop offset="0.77" stop-color="#8E22DE"/>
        <stop offset="0.97" stop-color="#6825F3"/>
        <stop offset="1" stop-color="#6226F7"/>
        </linearGradient>
        <linearGradient id="paint2_linear_360_477" x1="-6.85012" y1="97.5171" x2="148.225" y2="97.5171" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D41EB9"/>
        <stop offset="0.18" stop-color="#CF1EBB"/>
        <stop offset="0.37" stop-color="#C21FC2"/>
        <stop offset="0.56" stop-color="#AD20CE"/>
        <stop offset="0.77" stop-color="#8E22DE"/>
        <stop offset="0.97" stop-color="#6825F3"/>
        <stop offset="1" stop-color="#6226F7"/>
        </linearGradient>
        <linearGradient id="paint3_linear_360_477" x1="-6.85008" y1="101.453" x2="148.225" y2="101.453" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D41EB9"/>
        <stop offset="0.18" stop-color="#CF1EBB"/>
        <stop offset="0.37" stop-color="#C21FC2"/>
        <stop offset="0.56" stop-color="#AD20CE"/>
        <stop offset="0.77" stop-color="#8E22DE"/>
        <stop offset="0.97" stop-color="#6825F3"/>
        <stop offset="1" stop-color="#6226F7"/>
        </linearGradient>
        <linearGradient id="paint4_linear_360_477" x1="-6.85008" y1="98.4626" x2="148.225" y2="98.4626" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D41EB9"/>
        <stop offset="0.18" stop-color="#CF1EBB"/>
        <stop offset="0.37" stop-color="#C21FC2"/>
        <stop offset="0.56" stop-color="#AD20CE"/>
        <stop offset="0.77" stop-color="#8E22DE"/>
        <stop offset="0.97" stop-color="#6825F3"/>
        <stop offset="1" stop-color="#6226F7"/>
        </linearGradient>
        <linearGradient id="paint5_linear_360_477" x1="-6.85001" y1="158.192" x2="148.225" y2="158.192" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D41EB9"/>
        <stop offset="0.33" stop-color="#D11EBA"/>
        <stop offset="0.51" stop-color="#C922C0"/>
        <stop offset="0.66" stop-color="#BB27C9"/>
        <stop offset="0.79" stop-color="#A72FD6"/>
        <stop offset="0.91" stop-color="#8E39E7"/>
        <stop offset="1" stop-color="#7543F8"/>
        </linearGradient>
        <linearGradient id="paint6_linear_360_477" x1="-6.85012" y1="80.8924" x2="148.225" y2="80.8924" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D41EB9"/>
        <stop offset="0.18" stop-color="#CF1EBB"/>
        <stop offset="0.37" stop-color="#C21FC2"/>
        <stop offset="0.56" stop-color="#AD20CE"/>
        <stop offset="0.77" stop-color="#8E22DE"/>
        <stop offset="0.97" stop-color="#6825F3"/>
        <stop offset="1" stop-color="#6226F7"/>
        </linearGradient>
        <linearGradient id="paint7_linear_360_477" x1="-6.85008" y1="289.354" x2="148.225" y2="289.354" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D41EB9"/>
        <stop offset="0.18" stop-color="#CF1EBB"/>
        <stop offset="0.37" stop-color="#C21FC2"/>
        <stop offset="0.56" stop-color="#AD20CE"/>
        <stop offset="0.77" stop-color="#8E22DE"/>
        <stop offset="0.97" stop-color="#6825F3"/>
        <stop offset="1" stop-color="#6226F7"/>
        </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="buscador-top" #formselect>
      <mat-form-field class="buscador-empresa" *ngIf="this.clientes">
        <mat-select [(value)]="idCliente" (selectionChange)="cambiarCliente($event.value)" disableOptionCentering #insideform>
          <input matInput placeholder="Buscar cliente" id="buscador-clientes" type="text" (keydown)="escribir($event)" [value]="inputValue">
          <mat-option  *ngFor='let cliente of this.clientes' [matTooltip]="cliente.name.length < 36 ? null :  cliente.name" matTooltipClass="buscadorDashboardtooltip" class="buscadorDashboard" [(value)]="cliente.id">{{cliente.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="this.clientes && this.clientes.length === 1">
        <span class="cliente-solo">
          {{ (this.clientes[0].name.length>20) ? (this.clientes[0].name | slice:0:17)+'...': (this.clientes[0].name) }}
        </span>
      </div>
    </div>

    <div class="menu-div-hover">
    <mat-button-toggle-group name="MenuDashboard" aria-label="Opciones de Perfil" vertical>

      <mat-button-toggle value="Dashboard" checked routerLink="/dashboard">
        <h3 class="menu-item">
          <mat-icon mat-list-icon>dashboard</mat-icon> Dashboard
        </h3>
      </mat-button-toggle>
      <mat-button-toggle *ngFor="let menu of this.menu" value="Localización" >
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="menu-item">
                  <mat-icon mat-list-icon>{{menu.icon}}</mat-icon>  {{ menu.name }}
              </h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-button-toggle-group name="Submenu" aria-label="Opciones subemenu" vertical>
            <mat-button-toggle
              *ngFor="let submenu of menu.subMenus"
              (click)="this.navigateTo(submenu.path, submenu.target);"
            >
              <h3 class="sub-menu-item">{{submenu.name}}</h3>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </mat-expansion-panel>
      </mat-button-toggle>


    </mat-button-toggle-group>
    </div>

    <div class="favoritos">
      <div class="fav-1">
        <span>Mantenimiento</span>
      </div>
      <div class="fav-2">
        <span>Trackview Lite</span>
      </div>
      <div class="fav-3">
        <span>Temperatura</span>
      </div>
      <div class="fav-4">
        <span>TV Despacho</span>
      </div>
      <div class="fav-5">
        <span>TV Lite</span>
      </div>
    </div>

    <div class="bottom-menu">
      <button mat-flat-button id="cerrarSesionBtn" (click)="cerrarSesion()">Cerrar Sesión</button>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar class="background-header">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon aria-label="Sidebar menu">menu</mat-icon>
      </button>
      <span class="logo-wp" *ngIf="!sideBarOpen">
        <svg width="120" height="33" viewBox="0 0 150 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.2953 31.7487C9.09624 31.7487 7.04593 30.8983 5.54849 29.3984C3.99898 27.8489 3.19819 25.8506 3.19819 23.6516V14.1562H0V9.00932H3.19819V0.00976562H9.14583V9.00684H15.7926V14.1537H9.14583V23.5499C9.14583 24.7994 10.2466 25.9498 11.3449 25.9498H15.6934L17.2925 31.7487H11.2953Z" fill="url(#paint0_linear_360_477)"/>
          <path d="M20.0396 31.7496V8.85898H26.3368V10.7085C28.1367 8.90856 30.8341 8.30859 32.634 8.30859H34.1339L32.634 14.5562H31.6844C28.7366 14.5562 26.3368 16.8545 26.3368 19.6039V31.7496H20.0396H20.0396Z" fill="url(#paint1_linear_360_477)"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M34.7338 20.3015C34.7338 23.4501 35.9833 26.45 38.2319 28.7482C40.5302 30.9969 43.53 32.1968 46.6786 32.1968C48.4785 32.1968 50.5264 31.9489 52.3263 30.1986L52.5767 29.9482V31.7481H58.6235V8.85747H52.5767V10.8557L52.3263 10.6549C50.6776 9.15497 48.7785 8.40625 46.6786 8.40625C43.4804 8.40625 40.4806 9.60619 38.2319 11.8548C35.9833 14.1531 34.7338 17.1529 34.7338 20.3015ZM46.6786 26.3409C43.4804 26.3409 40.7806 23.5939 40.7806 20.2941C40.7806 17.0463 43.4804 14.2473 46.6786 14.2473C49.8768 14.2473 52.5767 16.9967 52.5767 20.2941C52.5767 23.5914 49.8768 26.3409 46.6786 26.3409Z" fill="url(#paint2_linear_360_477)"/>
          <path d="M78.7647 31.7481V19.5032C78.7647 16.8034 76.6152 14.7059 73.9153 14.7059C71.2155 14.7059 69.066 16.8554 69.066 19.5032V31.7481H62.8184V8.85747H69.066V10.5061C70.6155 8.95664 72.2642 8.40625 73.9153 8.40625C76.9152 8.40625 79.7142 9.55661 81.8116 11.6044C83.9115 13.7539 85.0594 16.553 85.0594 19.5007V31.7456H78.7622L78.7647 31.7481Z" fill="url(#paint3_linear_360_477)"/>
          <path d="M93.8259 30.5568L93.7664 30.5246C91.0418 28.9503 89.0262 26.4017 88.1609 23.3275C87.2957 20.2458 87.6775 16.9707 89.1848 14.2139C90.7591 11.4892 93.3078 9.47359 96.4316 8.59843C99.605 7.70591 102.821 8.04804 105.496 9.6397C106.113 9.98927 106.621 10.314 107.077 10.6487L107.903 11.3578L104.172 16.1972L103.239 15.4733C101.648 14.256 99.6992 13.7651 98.0158 14.2411C94.9837 15.0915 93.1416 18.5153 94.0342 21.6961C94.9267 24.8695 98.2835 26.8355 101.316 25.9852C102.999 25.5092 104.415 24.0787 105.106 22.1201L105.491 21.0788L111.389 23.2109L110.923 24.3266C110.863 24.5026 110.764 24.6836 110.655 24.867C110.496 25.2241 110.305 25.6406 110.097 26.0075C108.572 28.7222 106.024 30.7304 102.9 31.613C99.8182 32.4634 96.5927 32.0716 93.8284 30.5544L93.8259 30.5568Z" fill="url(#paint4_linear_360_477)"/>
          <path d="M135.494 31.7487C133.295 31.7487 131.245 30.8983 129.747 29.3984C128.198 27.8489 127.397 25.8506 127.397 23.6516V14.1562H124.199V9.00932H127.397V0.00976562H133.345V9.00684H139.496V14.1537H133.345V23.5499C133.345 24.7994 134.445 25.9498 135.544 25.9498H139.892L141.491 31.7487H135.494Z" fill="url(#paint5_linear_360_477)"/>
          <path d="M135.494 31.7487C133.295 31.7487 131.245 30.8983 129.747 29.3984C128.198 27.8489 127.397 25.8506 127.397 23.6516V14.1562H124.199V9.00932H127.397V0.00976562H133.345V9.00684H139.496V14.1537H133.345V23.5499C133.345 24.7994 134.445 25.9498 135.544 25.9498H139.892L141.491 31.7487H135.494Z" fill="#6226F7"/>
          <path d="M142.252 3.85022C142.252 2.80151 142.652 1.85197 143.403 1.15036C144.152 0.401633 145.101 0 146.152 0C148.252 0 150 1.75033 150 3.84774C150 5.94516 148.25 7.69549 146.152 7.69549C144.003 7.69549 142.255 5.94516 142.255 3.84774L142.252 3.85022Z" fill="#D41EB9"/>
          <path d="M142.902 9.20703H149.4V31.7406H144.39L142.902 25.9492V9.20703V9.20703Z" fill="#6226F7"/>
          <path d="M117.768 7.70135C115.668 7.70135 113.92 5.95102 113.92 3.8536C113.92 1.75371 115.67 0.00585938 117.768 0.00585938C118.816 0.00585938 119.766 0.405013 120.517 1.15622C121.266 1.85535 121.667 2.80489 121.667 3.85608C121.667 5.95598 119.917 7.70382 117.77 7.70382L117.768 7.70135Z" fill="url(#paint6_linear_360_477)"/>
          <path d="M121.015 25.9492V31.7406H114.517V9.20703H121.015V25.9492V25.9492Z" fill="url(#paint7_linear_360_477)"/>
          <defs>
          <linearGradient id="paint0_linear_360_477" x1="-6.85007" y1="158.192" x2="148.225" y2="158.192" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D41EB9"/>
          <stop offset="0.18" stop-color="#CF1EBB"/>
          <stop offset="0.37" stop-color="#C21FC2"/>
          <stop offset="0.56" stop-color="#AD20CE"/>
          <stop offset="0.77" stop-color="#8E22DE"/>
          <stop offset="0.97" stop-color="#6825F3"/>
          <stop offset="1" stop-color="#6226F7"/>
          </linearGradient>
          <linearGradient id="paint1_linear_360_477" x1="-6.85002" y1="148.984" x2="148.225" y2="148.984" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D41EB9"/>
          <stop offset="0.18" stop-color="#CF1EBB"/>
          <stop offset="0.37" stop-color="#C21FC2"/>
          <stop offset="0.56" stop-color="#AD20CE"/>
          <stop offset="0.77" stop-color="#8E22DE"/>
          <stop offset="0.97" stop-color="#6825F3"/>
          <stop offset="1" stop-color="#6226F7"/>
          </linearGradient>
          <linearGradient id="paint2_linear_360_477" x1="-6.85012" y1="97.5171" x2="148.225" y2="97.5171" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D41EB9"/>
          <stop offset="0.18" stop-color="#CF1EBB"/>
          <stop offset="0.37" stop-color="#C21FC2"/>
          <stop offset="0.56" stop-color="#AD20CE"/>
          <stop offset="0.77" stop-color="#8E22DE"/>
          <stop offset="0.97" stop-color="#6825F3"/>
          <stop offset="1" stop-color="#6226F7"/>
          </linearGradient>
          <linearGradient id="paint3_linear_360_477" x1="-6.85008" y1="101.453" x2="148.225" y2="101.453" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D41EB9"/>
          <stop offset="0.18" stop-color="#CF1EBB"/>
          <stop offset="0.37" stop-color="#C21FC2"/>
          <stop offset="0.56" stop-color="#AD20CE"/>
          <stop offset="0.77" stop-color="#8E22DE"/>
          <stop offset="0.97" stop-color="#6825F3"/>
          <stop offset="1" stop-color="#6226F7"/>
          </linearGradient>
          <linearGradient id="paint4_linear_360_477" x1="-6.85008" y1="98.4626" x2="148.225" y2="98.4626" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D41EB9"/>
          <stop offset="0.18" stop-color="#CF1EBB"/>
          <stop offset="0.37" stop-color="#C21FC2"/>
          <stop offset="0.56" stop-color="#AD20CE"/>
          <stop offset="0.77" stop-color="#8E22DE"/>
          <stop offset="0.97" stop-color="#6825F3"/>
          <stop offset="1" stop-color="#6226F7"/>
          </linearGradient>
          <linearGradient id="paint5_linear_360_477" x1="-6.85001" y1="158.192" x2="148.225" y2="158.192" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D41EB9"/>
          <stop offset="0.33" stop-color="#D11EBA"/>
          <stop offset="0.51" stop-color="#C922C0"/>
          <stop offset="0.66" stop-color="#BB27C9"/>
          <stop offset="0.79" stop-color="#A72FD6"/>
          <stop offset="0.91" stop-color="#8E39E7"/>
          <stop offset="1" stop-color="#7543F8"/>
          </linearGradient>
          <linearGradient id="paint6_linear_360_477" x1="-6.85012" y1="80.8924" x2="148.225" y2="80.8924" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D41EB9"/>
          <stop offset="0.18" stop-color="#CF1EBB"/>
          <stop offset="0.37" stop-color="#C21FC2"/>
          <stop offset="0.56" stop-color="#AD20CE"/>
          <stop offset="0.77" stop-color="#8E22DE"/>
          <stop offset="0.97" stop-color="#6825F3"/>
          <stop offset="1" stop-color="#6226F7"/>
          </linearGradient>
          <linearGradient id="paint7_linear_360_477" x1="-6.85008" y1="289.354" x2="148.225" y2="289.354" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D41EB9"/>
          <stop offset="0.18" stop-color="#CF1EBB"/>
          <stop offset="0.37" stop-color="#C21FC2"/>
          <stop offset="0.56" stop-color="#AD20CE"/>
          <stop offset="0.77" stop-color="#8E22DE"/>
          <stop offset="0.97" stop-color="#6825F3"/>
          <stop offset="1" stop-color="#6226F7"/>
          </linearGradient>
          </defs>
        </svg>
      </span>

      <span class="toolbar-spacer"></span>
      <p class="bienvenido-text">Bienvenido, <b>{{this.getUserName()}}</b></p>
      <!-- Borrar ngIf para que reaparezca boton con opciones de editar perfil y cerrar sesion -->
      <button *ngIf="false" mat-mini-fab color="primary" [matMenuTriggerFor]="belowMenu" >
          <span class="user-inicial-top">W</span>
          <span class="user-inicial-top">X</span>
      </button>
      <mat-menu #belowMenu="matMenu" yPosition="below" xPosition="after">
        <button mat-menu-item (click)="openConfiDialog()">
          <span class="menu-user-li">
            <svg width="20px" height="17px" viewBox="0 0 20 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="manage_accounts-24px" fill="#575C63" fill-rule="nonzero">
                        <g id="Group">
                            <circle id="Oval" cx="8" cy="4" r="4"></circle>
                            <path d="M8.67,9.02 C8.45,9.01 8.23,9 8,9 C5.58,9 3.32,9.67 1.39,10.82 C0.51,11.34 0,12.32 0,13.35 L0,16 L9.26,16 C8.47,14.87 8,13.49 8,12 C8,10.93 8.25,9.93 8.67,9.02 Z" id="Path"></path>
                            <path d="M18.75,12 C18.75,11.78 18.72,11.58 18.69,11.37 L19.83,10.36 L18.83,8.63 L17.38,9.12 C17.06,8.85 16.7,8.64 16.3,8.49 L16,7 L14,7 L13.7,8.49 C13.3,8.64 12.94,8.85 12.62,9.12 L11.17,8.63 L10.17,10.36 L11.31,11.37 C11.28,11.58 11.25,11.78 11.25,12 C11.25,12.22 11.28,12.42 11.31,12.63 L10.17,13.64 L11.17,15.37 L12.62,14.88 C12.94,15.15 13.3,15.36 13.7,15.51 L14,17 L16,17 L16.3,15.51 C16.7,15.36 17.06,15.15 17.38,14.88 L18.83,15.37 L19.83,13.64 L18.69,12.63 C18.72,12.42 18.75,12.22 18.75,12 Z M15,14 C13.9,14 13,13.1 13,12 C13,10.9 13.9,10 15,10 C16.1,10 17,10.9 17,12 C17,13.1 16.1,14 15,14 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </svg>
          </span>
          Menu Usuario</button>
        <button mat-menu-item>
          <span class="menu-user-li">
            <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>logout-24px</title>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="logout-24px" fill="#575C63" fill-rule="nonzero">
                        <path d="M15,4 L13.59,5.41 L16.17,8 L6,8 L6,10 L16.17,10 L13.59,12.58 L15,14 L20,9 L15,4 Z M2,2 L10,2 L10,0 L2,0 C0.9,0 0,0.9 0,2 L0,16 C0,17.1 0.9,18 2,18 L10,18 L10,16 L2,16 L2,2 Z" id="Shape"></path>
                    </g>
                </g>
            </svg>
            Cerrar Sesión
          </span>
        </button>
      </mat-menu>
    </mat-toolbar>

    <router-outlet (activate)="onActivate($event)"></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>
